import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/Layout";
import { Link } from "gatsby";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Container } from "@chakra-ui/react";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Roadmap | THE PROJECT STATUS" urlPage="/roadmap" mdxType="SEO" />
    <Container maxW="800px" minH="640px" px={4} mdxType="Container">
      <h1 {...{
        "id": "roadmap",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h1" {...{
          "href": "#roadmap",
          "aria-label": "roadmap permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Roadmap`}</h1>
      <h3 {...{
        "id": "prio-high",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#prio-high",
          "aria-label": "prio high permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Prio High`}</h3>
      <p>{`[ ]`}{` Infrastructure: Google Analytics`}</p>
      <p>{`[ ]`}{` Idea: Inbuilt Example-Cases.`}</p>
      <p>{`[ ]`}{` Documentation: Links and TOC for Infos.`}</p>
      <p>{`[ ]`}{` Documentation: Update screenshots`}</p>
      <p>{`[ ]`}{` Improvement: WIP / DONE automation in Kanban mode?`}</p>
      <p>{`[ ]`}{` Improvement: Progress report for KANBAN mode.`}</p>
      <p>{`[ ]`}{` Persons report: Time spent and forecasted`}</p>
      <p>{`[ ]`}{` Improvement: Make compact view for e.g. Tablets (move comments to new line)`}</p>
      <p>{`[ ]`}{` Improvement: Dealing with “comments required for colors other than green”.`}</p>
      <h3 {...{
        "id": "prio-medium",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#prio-medium",
          "aria-label": "prio medium permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Prio Medium`}</h3>
      <p>{`[ ]`}{` New function: Templates upload`}</p>
      <p>{`[ ]`}{` New function: Project selection based on checklist`}</p>
      <p>{`[ ]`}{` New function: Project invest calculation`}</p>
      <p>{`[ ]`}{` New function: Multi Project Management page`}</p>
      <p>{`[ ]`}{` New function: Priority button in deliverables?`}</p>
      <p>{`[ ]`}{` Marketing: Social Media Icons`}</p>
      <h3 {...{
        "id": "prio-low",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#prio-low",
          "aria-label": "prio low permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Prio Low`}</h3>
      <p>{`[ ]`}{` Improvement: Optimize keyboard inputs`}</p>
      <p>{`[ ]`}{` Improvement: ^Z to go back for parameter inputs.`}</p>
      <p>{`[ ]`}{` Idea: Include Progress in Status Sheet?`}</p>
      <p>{`[ ]`}{` Improvement: Add month view to change between date, week, months view for
Gantt-Chart`}</p>
      <p>{`[ ]`}{` Marketing: Add toast to build community`}</p>
      <p>{`[ ]`}{` Improvement: Change currency dimension "" → “T” → “M”`}</p>
      <p>{`[ ]`}{` Infrastructure: Update gatsby-plugin-sitemap.`}</p>
      <h3 {...{
        "id": "technical-improvements",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#technical-improvements",
          "aria-label": "technical improvements permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Technical Improvements`}</h3>
      <p>{`[ ]`}{` Build or remove: LinkMarkdown,
https://zslabs.com/articles/mdx-link-routing-in-gatsby`}</p>
      <p>{`[ ]`}{` Optimize re-renders with React.memo for dirty states in react-hook-form`}</p>
      <p>{`[ ]`}{` Logic: Chakra Text → Heading`}</p>
      <p>{`[ ]`}{` Logic: formatString: yyyy-MM-dd`}</p>
      <p>{`[ ]`}{` Logic: Change Day End and Day Start`}</p>
      <p>{`[ ]`}{` Test: Double check encryption for download files.`}</p>
      <p>{`[ ]`}{` Yarn 3`}</p>
      <h3 {...{
        "id": "inconsistencies",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#inconsistencies",
          "aria-label": "inconsistencies permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Inconsistencies`}</h3>
      <p>{`none`}</p>
      <h3 {...{
        "id": "open-issues-or-known-errors",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#open-issues-or-known-errors",
          "aria-label": "open issues or known errors permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Open Issues or Known Errors`}</h3>
      <p>{`none`}</p>
      <h3 {...{
        "id": "errors-that-cannot-be-re-constructed-any-more-was-probably-cache-error",
        "style": {
          "position": "relative"
        }
      }}><a parentName="h3" {...{
          "href": "#errors-that-cannot-be-re-constructed-any-more-was-probably-cache-error",
          "aria-label": "errors that cannot be re constructed any more was probably cache error permalink",
          "className": "anchor before"
        }}><svg parentName="a" {...{
            "aria-hidden": "true",
            "height": "20",
            "version": "1.1",
            "viewBox": "0 0 16 16",
            "width": "20"
          }}><path parentName="svg" {...{
              "fillRule": "evenodd",
              "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
            }}></path></svg></a>{`Errors that cannot be re-constructed any more (was probably cache error)`}</h3>
      <p>{`none`}</p>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      